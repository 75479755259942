import { Divider, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        display: 'flex',
        flex: 1,
        margin: theme.spacing(1)
    },
    icon: {

    },
}));

function SkillCard(props) {
    const classes = useStyles();

    return (
        <Paper elevation={8} className={classes.root}>
            <div className={classes.icon}>
                {props.icon}
            </div>
            <Typography variant='h5'>
                {props.text}
            </Typography>
            <Divider />
        </Paper>
    );
}

export default SkillCard;