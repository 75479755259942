import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline, createMuiTheme, ThemeProvider, Typography, Button, AppBar, Toolbar, IconButton, Paper } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import Label from './components/Label';
import clsx from 'clsx';

import Background from './res/bg.jpg';
import Particles from 'react-particles-js';

import "@fontsource/poppins";
import SkillCard from './components/SkillCard';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    headerImage: {
        height: '100vh',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    skillContainer: {
        marginLeft: '10%',
        marginRight: '10%',
    },
    skills: {
        flexWrap: 'wrap',
        display: 'flex',
        flex: 1,
    },
    titleText: {
        display: 'inline-block',
        textAlign: 'center'
    },
    subTitleText: {
        margin: theme.spacing(2),
        textAlign: 'center'
    },
    appBar: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    '@global': {
        html: {
            WebkitFontSmoothing: 'auto',
        },
    },
}));

const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
    typography: {
        fontFamily: 'Poppins'
    }
});

function App() {
    const classes = useStyles();

    const renderParticles = () => {
        return (
            <Particles
            style={{position: 'absolute'}}
            params={{
                "particles": {
                    "number": {
                        "value": 200,
                        "density": {
                            "enable": true,
                            "value_area": 4500
                        }
                    },
                    "line_linked": {
                        "enable": true,
                        "opacity": 0.3,
                        "width": 1,
                        "color": {
                            "value": "#2196f3",
                        }
                    },
                    "move": {
                        "direction": "random",
                        "speed": 3
                    },
                    "size": {
                        "value": 2
                    },
                    "opacity": {
                        "anim": {
                            "enable": true,
                            "speed": 5,
                            "opacity_min": 0.5
                        }
                    },
                    "color": {
                        "value": "#2196f3",
                    }
                },
                "interactivity": {
                    "events": {
                        "onclick": {
                            "enable": true,
                            "mode": "push"
                        }
                    },
                    "modes": {
                        "push": {
                            "particles_nb": 1
                        }
                    }
                },
                "retina_detect": true
            }} />
        );
    }

    const renderAppBar = () => {
        return (
            <AppBar position="absolute" className={classes.appBar} style={{ color: '#2196f3', background: 'transparent', boxShadow: 'none' }}>
                 <Toolbar>
                    <Button color="inherit" style={{textTransform: 'none'}}>Home</Button>
                    <Button color="inherit" style={{textTransform: 'none'}}>Skills</Button>
                    <Button color="inherit" style={{textTransform: 'none'}}>About Me</Button>
                    <Button color="inherit" style={{textTransform: 'none'}}>Projects</Button>
                </Toolbar>
            </AppBar>
        );
    }

    const renderContent = () => {
        return (
            <div className={classes.content}>
                <div className={classes.titleText}>
                <Typography color='primary' variant='h2' align='center'>
                    Welcome
                </Typography>
                <Typography color='primary' variant='h2' style={{marginBottom: 24}} align='center'>
                    Learn more about me below!
                </Typography>
                <Button variant='contained' color='primary'>
                    Next
                </Button>
                </div>
            </div>
        );
    }

    const renderSkills = () => {
        return (
            <div className={classes.skillContainer}>
                <div className={classes.subTitleText}>
                    <Typography color='primary' variant='h4' align='center'>
                        Skills
                    </Typography>
                </div>
                <div className={classes.skills}>
                    <SkillCard text="Test 1" />
                    <SkillCard text="Test 2" />
                    <SkillCard text="Test 3" />
                </div>
                <div className={classes.skills}>
                    <SkillCard text="Test 1" />
                    <SkillCard text="Test 2" />
                    <SkillCard text="Test 3" />
                </div>
            </div>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className={clsx(classes.root, classes.headerImage)}>
                {renderParticles()}
                {renderAppBar()}
                {renderContent()}
            </div>
                {renderSkills()}
        </ThemeProvider>
    );
}

export default App;
