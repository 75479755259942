import { Divider, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    titleText: {
        display: 'inline-block',
        textAlign: 'center'
    }
}));

function Label(props) {
    const classes = useStyles();

    return (
        <div className={classes.labelRoot}>
            <Typography variant='h5'>
                {props.text}
            </Typography>
            <Divider />
        </div>
    );
}

export default Label;